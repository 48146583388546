import React, { useMemo, useState } from "react";
import "./LandingPage.css";
import { useTranslation } from "react-i18next";
import "../../../common/components/TabBar/TabBar.css";
import { FjdButton, FjdCard, FjdCollapsibleCard, FjdIcon, FjdRadio, FjdStack } from "fjd-react-components";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { URL_BACKGROUND_BANNER } from "../../../common/url";
import { OnlineServicesV1, StateCategory } from "../../api/onlinedienste/OnlineServicesV1";
import { Stack } from "../../../common/components/Stack/Stack";
import { OnlineServiceList } from "../../../common/components/OnlineServiceList/OnlineServiceList";
import { Banner } from "../../../common/components/Banner/Banner";
import { FlexSpacer } from "../../../common/components/FlexSpacer/FlexSpacer";
import { Heading } from "../../../common/components/Heading/Heading";
import { CallToAction } from "../../../common/components/CallToAction/CallToAction";
import {
  OnlineServiceInformationModal,
  OzgRequestDetailsData
} from "./OnlineServiceInformation/OnlineServiceInformationModal";
import { FJD_OZG_CONTACT_MAILTO } from "../../../common/contact";
import { SearchFilterBar } from "../../../common/components/SearchFilterBar/SearchFilterBar";
import { fetchFormulars } from "../../api/formulare/formularApi";
import { KeyValueList } from "../../../common/components/KeyValueList/KeyValueList";
import { FormularInformationModal } from "./FormularInformation/FormularInformationModal";
import OnlineDienstImage from "../../../assets/img/Online-Dienst.png";
import FormularPrintImage from "../../../assets/img/Formulare-PDF-Print.png";
import FormularAccessibleImage from "../../../assets/img/Formulare-barrierefrei.png";
import { Modal } from "../../../common/components/Modal/Modal";
import {
  FilterValues,
  OnlineServiceStatus,
} from "../../api/onlinedienste/OnlineServiceV2";

import {fetchOnlineServicesV2} from "../../api/onlinedienste/onlineDiensteApi";
import { MARKETPLACE_URL_BASE } from "../../../common/libs/axiosClient";

const PAGE_SIZE = 20;
const MARKETPLACE_URL = `${MARKETPLACE_URL_BASE}`

enum ServiceType {
  OnlineDienst = "OnlineDienst",
  Formular = "Formular"
}

type ServiceTypeFilter = ServiceType.Formular | ServiceType.OnlineDienst | "all";
type MarketplaceService = OnlineServicesV1 & { type: ServiceType };

const MARKETPLACE_SERVICE_FILTERS: { value: ServiceTypeFilter; label: string; defaultChecked: boolean }[] = [
  { value: "all", label: "all", defaultChecked: true },
  { value: ServiceType.OnlineDienst, label: "onlineDienste", defaultChecked: false },
  { value: ServiceType.Formular, label: "formulare", defaultChecked: false }
];
const ONLINE_DIENST_ACCORDION_TRANSLATION_KEY = [
  "datamodell",
  "config",
  "viewer",
  "serviceAcc",
  "payment",
  "interface",
  "auth",
  "language"
];
const FORMULAR_ACCORDION_TRANSLATION_KEYS = [
  "layout",
  "transformation",
  "law",
  "updates",
  "interfaces",
  "editor",
  "onprem",
  "newsletter"
];
const NESTED_FORMULAR_ACCORDION_TRANSLATION_KEYS = [
  "transformationOnlinePdf",
  "transformationPrintPdf",
  "transformationAccessibilityHtml"
];

export function LandingPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [searchResults, setSearchResults] = useState<MarketplaceService[]>([]);
  const [filteredServicesByType, setFilteredServicesByType] = useState<MarketplaceService[]>([]);
  const [selectedServiceType, setSelectedServiceType] = useState<ServiceTypeFilter>("all");

  const [selectedImage, setSelectedImage] = useState<{ src: string; alt: string; onClose: () => void } | null>(null);



  const mapStatusToStateCategory = (status: OnlineServiceStatus): StateCategory | undefined => {
    switch (status) {
      case OnlineServiceStatus.Released:
        return StateCategory.Finished;
      case OnlineServiceStatus.WorkedOn:
        return undefined;
      default:
        return undefined;
    }
  };

  // TODO: instead of using a custom interface we should pass the desired service directly without abstraction (OnlineServiceInformationModal)
  const [selectedService, setSelectedService] = useState<(OzgRequestDetailsData & { type: ServiceType }) | null>(null);
  const [filterValues] = useState<FilterValues>({
    afterUseModel: undefined,
    pricingModel: undefined,
    platformIds: undefined
  } as FilterValues);

  const { data: formulars } = useQuery({
    queryKey: [fetchFormulars.name],
    queryFn: fetchFormulars,
    staleTime: Infinity,
    select: (data) => data.map<MarketplaceService>((service) => ({ ...service, type: ServiceType.Formular })),
    onSuccess: (data) => {
      // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
      const mergedData = [...(onlineServices ?? []), ...data];
      setFilteredServicesByType(mergedData);
    }
  });

  const {data: onlineServices} = useQuery({
    retryDelay: 5000,
    queryKey: [fetchOnlineServicesV2.name, filterValues],
    queryFn: () => fetchOnlineServicesV2(filterValues),
    staleTime: Infinity,
     select: (data) => ( data.map<MarketplaceService>(onlineservice => ({
      kennung: onlineservice.identifier,
      vorschauLink: onlineservice.previewLink,
      leikaId: onlineservice.leikaIds?.join(",") ?? " ",
      titel: onlineservice.title,
      createdAt:" ",
      status: mapStatusToStateCategory(onlineservice.status) ?? StateCategory.Finished ,
      version: onlineservice.version,
      updatedAt: " " ,
      id: onlineservice.onlineServiceId,
      ozgId:onlineservice.ozgId ?? "",
      fimId: onlineservice.fimId,
      type: ServiceType.OnlineDienst


    }) )),
    onSuccess : (data) => {setFilteredServicesByType([...(formulars ?? []), ...data]);}

  });


  const mergedServices = useMemo(() => [...(onlineServices ?? []), ...(formulars ?? [])], [formulars, onlineServices]);

  const onSearchComplete = (data: MarketplaceService[]) => {
    setSearchResults(data);
  };

  const onServiceTypeSelected: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const filter = event.target.value as ServiceTypeFilter;
    const filteredService =
      filter === "all" ? mergedServices : mergedServices.filter((service) => service.type === filter);

    setFilteredServicesByType(filteredService);
    setSelectedServiceType(filter);
  };

  const openOnlineServiceInformationModal = (service: MarketplaceService) => {
    setSelectedService({
      leikaIds: service.leikaId,
      serviceTitle: service.titel,
      fimId: service.fimId,
      ozgId: service.ozgId,
      identifier: service.kennung,
      type: service.type
    });
  };

  const closeOnlineServiceInformationModal = () => {
    setSelectedService(null);
  };

  const serviceListHeadline = useMemo(() => {
    const onlineDiensteCount = searchResults.filter((results) => results.type === ServiceType.OnlineDienst).length;
    const formularCount = searchResults.length - onlineDiensteCount;
    if (selectedServiceType === ServiceType.OnlineDienst)
      return t("landingPage.searchResultsOnlineService", { onlineDiensteCount });
    if (selectedServiceType === ServiceType.Formular) return t("landingPage.searchResultsFormular", { formularCount });
    return t("landingPage.searchResultsAll", { onlineDiensteCount, formularCount });
  }, [selectedServiceType, searchResults, t]);

  return (
    <Stack spacing="xxl" orientation="vertical">
      <Banner
        heading={t("banner.heading")}
        imageUrl={URL_BACKGROUND_BANNER}
        subheading={t("banner.subHeading")}
        hint={t("banner.countOnlineServices", {
          onlineServiceCount: onlineServices?.length ?? 0,
          formularCount: formulars?.length ?? 0
        })}
      />
      <FjdCollapsibleCard heading={<Heading level={3} text={t("landingPage.serviceAccordion.description")} />}>
        <Stack orientation="horizontal" className="card-container">
          <FjdCard
            footer={
              <FjdStack className="card-footer-container" orientation="horizontal" spacing="l">
                {t("landingPage.serviceAccordion.onlineDienst.footer")}
                {/* TODO: Fix accessibility */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  alt={t("landingPage.serviceAccordion.onlineDienst.footer")}
                  height={120}
                  src={OnlineDienstImage}
                  onClick={() =>
                    setSelectedImage({
                      alt: t("landingPage.serviceAccordion.onlineDienst.footer"),
                      src: OnlineDienstImage,
                      onClose: () => setSelectedImage(null)
                    })
                  }
                />
              </FjdStack>
            }
            padding="xl"
          >
            <KeyValueList heading={t("landingPage.serviceAccordion.onlineDienst.headline")}>
              <ul style={{ margin: "4px 0px 4px 16px", width: "100%" }}>
                {ONLINE_DIENST_ACCORDION_TRANSLATION_KEY.map((translationKey) => (
                  <li key={translationKey}>{t(`landingPage.serviceAccordion.onlineDienst.${translationKey}`)}</li>
                ))}
              </ul>
            </KeyValueList>
          </FjdCard>
          <FjdCard
            footer={
              <FjdStack orientation="horizontal" spacing="l">
                {t("landingPage.serviceAccordion.formular.footer")}
                {/* TODO: Fix accessibility */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  alt={t("landingPage.serviceAccordion.formular.footer")}
                  height={120}
                  src={FormularPrintImage}
                  onClick={() =>
                    setSelectedImage({
                      alt: t("landingPage.serviceAccordion.formular.footer"),
                      src: FormularPrintImage,
                      onClose: () => setSelectedImage(null)
                    })
                  }
                />
                {/* TODO: Fix accessibility */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  alt={t("landingPage.serviceAccordion.formular.footer")}
                  height={120}
                  src={FormularAccessibleImage}
                  onClick={() =>
                    setSelectedImage({
                      alt: t("landingPage.serviceAccordion.formular.footer"),
                      src: FormularAccessibleImage,
                      onClose: () => setSelectedImage(null)
                    })
                  }
                />
              </FjdStack>
            }
            padding="xl"
          >
            <KeyValueList heading={t("landingPage.serviceAccordion.formular.headline")}>
              <ul style={{ margin: "4px 0px 4px 16px", width: "100%" }}>
                {FORMULAR_ACCORDION_TRANSLATION_KEYS.map((translationKey) => (
                  <React.Fragment key={translationKey}>
                    <li>{t(`landingPage.serviceAccordion.formular.${translationKey}`)}</li>

                    {translationKey === "transformation" ? (
                      <ul>
                        {NESTED_FORMULAR_ACCORDION_TRANSLATION_KEYS.map((nestedTranslationKey) => (
                          <li key={nestedTranslationKey}>
                            {t(`landingPage.serviceAccordion.formular.${nestedTranslationKey}`)}
                          </li>
                        ))}
                      </ul>
                    ) : undefined}
                  </React.Fragment>
                ))}
              </ul>
            </KeyValueList>
          </FjdCard>
        </Stack>
      </FjdCollapsibleCard>
      <Heading text={t("landingPage.searchHeadline")} className="search-headline" level={2} />
      <SearchFilterBar<MarketplaceService>
        searchIn={["titel", "leikaId", "ozgId"]}
        placeholder={t("landingPage.searchBarPlaceholder")}
        menuItemLabel={(result) => `${result.titel}`}
        data={filteredServicesByType}
        initialColumnFilter={searchParams.get("searchTag")}
        onSearchCompleted={onSearchComplete}
        searchDelay={200}
        menuItemKeyAccessor={(item) => item.id}
      >
        <FjdStack className="service-radio-group" orientation="horizontal" spacing="l" role="radiogroup">
          {MARKETPLACE_SERVICE_FILTERS.map((filter) => (
            <FjdRadio
              key={filter.value}
              name="serviceTypeFilter"
              id={filter.value}
              value={filter.value}
              label={t(`landingPage.radioButton.${filter.label}`)}
              checked={selectedServiceType === filter.value}
              onChange={onServiceTypeSelected}
            />
          ))}
        </FjdStack>
      </SearchFilterBar>

      <OnlineServiceList>
        <OnlineServiceList.Headline>{serviceListHeadline}</OnlineServiceList.Headline>
        <OnlineServiceList.List
          visibleItems={PAGE_SIZE}
          loadAdditionItemsLabel={t("landingPage.loadAdditionOnlineServicesButtonLabel")}
        >
          {searchResults.map((item) => (
            <OnlineServiceList.ListItem
              avatar={<FjdIcon glyph={item.type === ServiceType.OnlineDienst ? "screen" : "document"} />}
              key={item.id}
              listItemKey={item.id}
              onPreviewButtonClick={
                item.vorschauLink?.length > 0
                  ? () => {
                    try {
                      // Versuche, eine absolute URL zu erstellen
                      const absoluteUrl = new URL(item.vorschauLink);
                      window.open(absoluteUrl.href, "_blank");
                    } catch (e) {
                      // Falls die URL relativ ist, hänge sie an localhost:8080 an
                      const absoluteUrl = new URL(item.vorschauLink, MARKETPLACE_URL);
                      window.open(absoluteUrl.href, "_blank");
                    }
                    }
                  : undefined
              }
              onDetailsButtonClick={() => openOnlineServiceInformationModal(item)}
              previewButtonTooltipLabel={t("onlineServiceList.preview")}
              detailButtonTooltipLabel={t("onlineServiceList.details")}
              preparationTooltipLabel={t("onlineServiceList.inPreparation")}
              headline={item.titel}
              isEnabled={item.status !== StateCategory.Finished}
              subHeadlines={
                item.type === ServiceType.OnlineDienst
                  ? [
                      t(`onlineServiceList.${item.type}`),
                      `${t("onlineServiceList.ozgId")}: ${item.ozgId} `,
                      `${t("onlineServiceList.leikaId")}: ${item.leikaId} `
                    ]
                  : [
                      `${item.type}`,
                      `${t("onlineServiceList.identifier")}: ${item.kennung} `
                    ]
              }
            />
          ))}
        </OnlineServiceList.List>
      </OnlineServiceList>

      <FlexSpacer />
      <FlexSpacer />
      <CallToAction
        appeal={t("landingPage.callToAction.appeal")}
        note={t("landingPage.callToAction.note")}
        action={
          <FjdButton
            appearance="outline"
            label={t("landingPage.callToAction.buttonLabel")}
            href={FJD_OZG_CONTACT_MAILTO}
          />
        }
      />
      <FlexSpacer />
      <FlexSpacer />
      {selectedService?.type === ServiceType.OnlineDienst && (
        <OnlineServiceInformationModal
          isOpen
          handleModal={closeOnlineServiceInformationModal}
          onlineService={selectedService}
        />
      )}
      {selectedService?.type === ServiceType.Formular && (
        <FormularInformationModal isOpen handleModal={closeOnlineServiceInformationModal} formular={selectedService} />
      )}
      {selectedImage && (
        <Modal onClose={() => setSelectedImage(null)} open heading={selectedImage.alt}>
          <img src={selectedImage.src} alt={selectedImage.alt} width="80%" />
        </Modal>
      )}
    </Stack>
  );
}
