export enum StateCategory {
  Finished = "READY"
}

export interface OnlineServicesV1 {
  kennung: string;
  version: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  status: StateCategory;
  titel: string;
  vorschauLink: string;
  leikaId: string;
  ozgId: string;
  fimId: string;
}

export interface FileMetadata {
  fileName: string;
  fileId?: string;
}

export interface OnlineServiceVersionCreateOrUpdateProps {
  previewLink?: string;
  fimId?: string;
  leikaIds?: string[];
  ozgId?: string;
  releaseNote?: string;
  releaseNoteAttachments?: FileMetadata[];
}

export interface NewOnlineDienstProps extends OnlineServiceVersionCreateOrUpdateProps {
  identifier: string;
}

export interface NewWorkingVersionProps {
  previewLink: string;
  releaseNote?: string;
  releaseNoteAttachments?: File[];
  leikaIds?: string[];
  ozgId?: string;
}

export interface OnlineServiceTemplateFlat {
  id: string;
  identifier: string;
  lastModifiedByUser: string;
  lastModifiedAt: string;
  workingVersionProducerVersion: string;
  workingVersionTitle: string;
  workingVersionId: string;
  releasedVersionProducerVersion: string;
  templateVersionId: string;
  releasedVersionId: string;
  releasedVersionTitle: string;
}

export interface OnlineServiceTemplateVersion {
  updatedAt: string;
  createdAt: string;
  lastModifiedByUser: string;
  status: string;
  title: string;
  previewLink: string | null;
  leikaIds: string[];
  ozgId: string | null;
  fimId: string;
  templateVersionId: string;
  producerVersion: string;
  onlineServiceTemplatePackageId: string;
  releaseNote?: string | null;
  releaseNoteAttachments?: FileMetadata[];
}

export interface OnlineServiceTemplate {
  id: string;
  createdAt: string;
  updatedAt: string;
  lastModifiedByUser: string;
  identifier: string;
  version: string;
  versions: OnlineServiceTemplateVersion[];
  releaseNote?: string;
  releaseNoteAttachments?: FileMetadata[];
}

export interface ValidationResult {
  title: string;
  version: string;
  languages: string;
}
